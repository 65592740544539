<template>
	<div style="width: 100vw;height: 100vh;">
		<el-container class="h-100 w-100">
			<!-- <el-aside class="d-flex flex-column h-100 bg-home" style="width: 10vw;min-width: 160px;"> -->
			<div style="background-color: #1c95d4;">
				<el-menu :default-active="defaultactive" class="el-menu-vertical-demo" background-color="#1c95d4"
					text-color="#fff" active-text-color="#1c95d4" :unique-opened="false" :router="false" @select=""
					:collapse="imgshow">
					<el-menu-item-group>
						<div class="text-center title-font">
							<div v-if="!imgshow" style="font-size: 18px;">
								托付保集成平台
							</div>
							<div v-if="imgshow">
								<img style="width: 25px;height: 25px;" src="../assets/images/logo.png" alt="">
							</div>
						</div>
					</el-menu-item-group>
					<el-submenu :index="String(index)" v-for="(item,index) in systemData" :key="index">
						<template slot="title">
							<i :class="iconarr[index]" style="color: #fff;"></i>
							<span>{{item.groupName}}</span>
						</template>
						<el-menu-item-group>
							<el-menu-item :index="item1.homepage" v-for="(item1,index1) in item.systemDetails"
								:key="index1" @click="tourl(item1)">
								<div class="d-flex align-items-center">
									<div>
										<div style="background-color: #fff;width: 7px;height: 7px;border-radius: 50%;">
										</div>
									</div>
									<div style="margin-left: 10px;">
										{{item1.name}}
									</div>
								</div>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
				</el-menu>
			</div>
			<!-- </el-aside> -->
			<el-container>
				<el-header style="background-color: #1c95d4;">
					<div class="d-flex flex-row align-items-center justify-content-between"
						style="background-color: #1c95d4;height: 60px;padding: 0 30px;">
						<div class="d-flex flex-row align-items-center" style="cursor: pointer;">
							<img src="../assets/images/left.png" style="width: 25px;height: 25px;" alt=""
								v-if="!imgshow" @click="imgshow = !imgshow">
							<img src="../assets/images/right.png" style="width: 25px;height: 25px;" alt=""
								v-if="imgshow" @click="imgshow = !imgshow">
						</div>
						<div></div>
						<div class="d-flex flex-row align-items-center">
							<div style="color: white;margin-right: 30px;" @click="content">
								<span style="cursor: pointer;">意见反馈</span>
							</div>
							<div class="d-flex align-items-center"
								style="border: 1px solid #1c95d4;border-radius: 50%;">
								<img src="../assets/images/touxiang.png" width="35px" height="35px"
									style="border-radius: 50%;" alt="">
							</div>
							<div class="d-flex align-items-center" style="margin-left: 10px;">
								<el-dropdown trigger="click" @command="clickname">
									<span class="el-dropdown-link" style="font-size: 14px;color: #fff;">
										{{userData.realName}}<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown" class="text-center">
										<el-dropdown-item command="toinfo">查看个人信息</el-dropdown-item>
										<el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
										<el-dropdown-item command="logout">退出登录</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</el-header>
				<el-main>
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
		<el-dialog title="密码修改" :visible.sync="dialogVisible" :before-close="handleClose">

			<el-form size="small" :model="resetForm" :rules="resetFormRules" ref="resetForm" label-width="100px">
				<el-form-item label="旧密码：" prop="password" style="line-height: 20px;width: 500px;">
					<el-input type="password" v-model="resetForm.password" auto-complete="off" clearable show-password
						style="width: 400px;"></el-input>
				</el-form-item>
				<el-form-item label="新密码：" prop="newpwd" style="line-height: 20px;width: 500px;">
					<el-input type="password" v-model="resetForm.newpwd" auto-complete="off" clearable show-password
						style="width: 400px;"></el-input>
				</el-form-item>
				<el-form-item label="确认密码：" prop="newpwd1" style="line-height: 20px;width: 500px;">
					<el-input type="password" v-model="resetForm.newpwd1" auto-complete="off" clearable show-password
						style="width: 400px;"></el-input>
				</el-form-item>
			</el-form>
			<div style=" text-align:center;">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button @click="reset('resetForm')">重置</el-button>
				<el-button type="primary" @click.native.prevent="toAmend">确 定</el-button>
			</div>

		</el-dialog>
		<el-dialog title="意见反馈" :visible.sync="dialogVisiblefalse" width="80%" :before-close="handleClose">
			<div class="d-flex justify-content-between">
				<div></div>
				<div></div>
				<div><el-button type="warning" size="medium" @click="addlist">新增</el-button></div>
			</div>
			<div style="margin-top: 15px;">
				<el-table :data="tableData" border style="width: 100%" height="500px"
					:header-cell-style="{background:'#e3e7ea', color:'#93969c', textAlign:'center'}">
					<el-table-column prop="number" label="工单编号" align="center">
					</el-table-column>
					<el-table-column prop="systemName" label="所属系统" align="center">
					</el-table-column>
					<el-table-column prop="type" label="反馈类型" :formatter="type" align="center">
					</el-table-column>
					<el-table-column prop="description" label="反馈描述" align="center">
					</el-table-column>
					<el-table-column prop="status" label="反馈状态" align="center" :formatter="status">
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间" align="center">
					</el-table-column>
					<el-table-column prop="createByName" label="创建人" align="center">
					</el-table-column>
					<el-table-column prop="" label="操作" align="center">
						<template slot-scope="scope">
							<div style="display: flex;justify-content: space-between;">
								<div style="color: #1c95d4;cursor: pointer;" @click="detail(scope.row,1)">
									查看
								</div>
								<div style="color: #ffaa00;cursor: pointer;" @click="detail(scope.row,2)">
									修改
								</div>
								<div style="color: green;cursor: pointer;" @click="detail(scope.row,3)">
									确认
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="text-right">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page.sync="current" :page-size="sizepage" layout="total, prev, pager, next"
						:total="total">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog :title="title" :visible.sync="adddialogVisiblefalse" width="30%" :before-close="handleClose">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="系统名称" prop="systemId">
						<el-select style="width: 100%;" v-model="ruleForm.systemId" placeholder="请选择所属系统" filterable
							size='mini' clearable>
							<el-option v-for="item in SystemOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="反馈类型" prop="type">
						<el-select style="width: 100%;" size="mini" v-model="ruleForm.type" placeholder="请选择反馈类型">
							<el-option label="系统建议" value="1"></el-option>
							<el-option label="系统问题" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="反馈类型" prop="description">
						<el-input v-model="ruleForm.description" type="textarea" placeholder="反馈描述"></el-input>
					</el-form-item>
					<el-form-item label="上传图片" prop="">
						<el-upload ref="upload" action="#" :multiple='false' :limit="1" :on-change="uploadFileQuery"
							:auto-upload="false" :show-file-list='true' style="width: 300px;" size="small">
							<el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
						</el-upload>
					</el-form-item>
					<div style="" v-if="imgurl !== ''">
						<img style="width: 200px;height: 200px;" @click="bigimg = true" :src="url + imgurl" alt="" />
					</div>
					<el-form-item label="备注" prop="remark">
						<el-input v-model="ruleForm.remark" type="textarea" width="100" height="100"
							placeholder="备注"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="adddialogVisiblefalse = false">取 消</el-button>
				<el-button type="primary" @click="ok()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="图片" :visible.sync="bigimg" width="80%" :before-close="handleClose">
			<div>
				<img :src="url + imgurl" alt="" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import 'lib-flexible'
	import Cookies from 'js-cookie'
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入新密码"));
				} else if (value.toString().length < 6 || value.toString().length > 15) {
					callback(new Error("密码长度为6-15位"));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.resetForm.newpwd) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				iconarr: ['el-icon-s-marketing', 'el-icon-s-data', 'el-icon-menu', 'el-icon-s-management',
					'el-icon-s-cooperation', 'el-icon-share', 'el-icon-s-opportunity', 'el-icon-s-tools',
					'el-icon-s-help',
				],
				page: 1,
				pageSize: 100,
				current: 1,
				sizepage: 10,
				total: 0,
				tableData: [],
				systemData: [],
				SystemOptions: [],
				url:'',
				imgurl:'',
				title: '新增反馈',
				userData: {},
				user: {},
				nowdate: '',
				ruleForm: {
					systemId: '',
					type: '',
					description: '',
					imgList: [],
					status: '1',
					remark: '',
				},
				rules: [],
				squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
				defaultactive: '',
				imgshow: false,
				dialogVisiblefalse: false,
				adddialogVisiblefalse: false,
				dialogVisible: false,
				bigimg:false,
				resetForm: {
					//传给后台所需要的参数
					newpwd1: "",
					password: "",
				},
				resetFormRules: {
					password: [{
						required: true,
						message: "请输入旧密码",
						trigger: 'blur'
					}],
					newpwd: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					newpwd1: [{
						required: true,
						validator: validatePass2,
						trigger: "blur"
					}]
				}
			}
		},
		mounted() {
			this.getSystemList()
			this.getuserinfor()
			this.getSystem()
			this.getUrl()
		},
		destroyed() {

		},
		methods: {
			getUrl() {
				this.$axios.get(
					`third/v1/oss/domainPath/integration`).then(
					res => {
						console.log(res);
						if (res.data.code == '1') {
							this.url = res.data.data
						}
					})
			},
			getList(id) {
				console.log(id);
				this.$axios.get(
					`user/v1/getWorkOrderPage?page=${this.current}&pageSize=${this.sizepage}&activeUserId=${id}`).then(
					res => {
						console.log(res);
						if (res.data.code == '1') {
							this.tableData = res.data.data.records
							this.total = res.data.data.total
						}
					})
			},
			// 跳转到url
			tourl(item) {
				Cookies.set('windowloacl', 'create', {
					domain: '.tofobao.com'
				})
				let that = this
				Cookies.remove('routers', {
					domain: '.tofobao.com'
				})
				Cookies.remove('tabactive', {
					domain: '.tofobao.com'
				})
				window.open(item.homepage, "_blank");
			},
			// 获取系统列表
			getSystemList() {
				let that = this
				//获取系统列表
				this.$axios.get('rbac/v1/getSystemGroupListBy?abbr=&page=' + this.page + '&pageSize=' + this
					.pageSize + '&visible=true' + '&mySystem=true').then(res => {
					if (res.data.code == '1') {
						that.systemData = res.data.data
					}
				})
			},
			// 确认是否登陆
			istologin() {
				let backlogin = Cookies.get('tologin', {
					domain: '.tofobao.com'
				})
				if (backlogin == '2') {
					this.$message.info('令牌已过期!')
					Cookies.remove('tologin', {
						domain: '.tofobao.com'
					})
					Cookies.remove('token', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('rbacMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('projectMeaList', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userData', {
						domain: '.tofobao.com'
					})
					Cookies.remove('userId', {
						domain: '.tofobao.com'
					})
					sessionStorage.removeItem('token')
					this.$router.push({
						path: 'Login'
					})
				}
			},
			//确认修改
			toAmend() {
				let userDataCookies = JSON.parse(Cookies.get('userData'))
				this.$refs.resetForm.validate(valid => {
					if (valid) {
						this.$axios.post('user/v1/updateUserPasswordById', {
								// id : this.userData.id,
								id: userDataCookies.id,
								newPassword: this.resetForm.newpwd1,
								oldPassword: this.resetForm.password,
							}).then(res => {
								if (res.data.code != 1) {
									return false;
								}
								if (res.data.code == 1) {
									this.$message.success("修改成功,3秒后跳转到登录页！");
									setTimeout(() => {
										this.$router.push({
											path: 'Login'
										})
									}, 3000);
									Cookies.remove('token', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('rbacMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('projectMeaList', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userData', {
										domain: '.tofobao.com'
									})
									Cookies.remove('userId', {
										domain: '.tofobao.com'
									})
									sessionStorage.removeItem('token')
									sessionStorage.removeItem('userdata')
								}
								ic
							})
							.catch(() => {});
					}
				});
			},
			// 获取用户信息
			getuserinfor() {
				let that = this
				this.$axios.get('user/v1/getCurrentUserDetail').then(res => {
					if (res.data.code == '1') {
						sessionStorage.setItem('userdata', JSON.stringify(res.data.data))
						Cookies.set('userData', JSON.stringify(res.data.data), {
							domain: '.tofobao.com'
						})
						Cookies.set('userId', res.data.data.id, {
							domain: '.tofobao.com'
						})
						that.userData = res.data.data
						this.getList(res.data.data.id)
					}
				})
			},
			// 点击名字
			clickname(e) {
				let that = this
				if (e == 'toinfo') {
					this.$message.info('暂未开发，请耐心等待')
				} else if (e == "logout") {
					this.$confirm('确认退出？').then(_ => {
						Cookies.remove('token', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('rbacMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('projectMeaList', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userData', {
							domain: '.tofobao.com'
						})
						Cookies.remove('userId', {
							domain: '.tofobao.com'
						})
						sessionStorage.removeItem('token')
						sessionStorage.removeItem('userdata')
						this.$router.push({
							path: 'Login'
						})
						this.$message({
							message: '退出成功！',
							type: 'success',
							debugger: 0
						});
						Cookies.get('returnLogin', 'one', {
							domain: '.tofobao.com'
						})
					}).catch(_ => {});
				} else if (e == "changePassword") {
					this.dialogVisible = true;
					this.resetForm = {};

				}
			},
			reset(formName) {
				this.$refs[formName].resetFields();
			},
			//弹出修改密码框
			handleClose(done) {
				// done();
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			content() {
				this.dialogVisiblefalse = true
			},
			getSystem() {
				this.$axios.get('rbac/v1/getSystemListBy?page=1&pageSize=100').then((res) => {
					if (res.data.code == '1') {
						this.SystemOptions = res.data.data.records.map((item) => {
							return {
								value: item.id,
								label: item.name
							}
						})
					} else {
						this.$message.info(res.data.message)
					}

				})
			},
			addlist() {
				this.adddialogVisiblefalse = true
				this.ruleForm = {
					systemId: '',
					type: '',
					description: '',
					imgList: [],
					status: '1',
					remark: '',
				}
			},
			// 上传文件
			uploadFileQuery(item, fileList) {
				console.log(item, fileList);
				let that = this
				let formData = new FormData()

				let files = item.raw
				formData.append('files', files)
				this.$axios({
					url: 'third/v1/oss/uploads?abbr=integration&folder=fankui', //后端提供的接口
					method: 'post',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': this.token
					}
				}).then(res => {

					if (res.data.code == '1') {
						let list = {
							fileName: item.name,
							url: res.data.data[0]
						}
						this.imgurl = res.data.data[0]
						this.ruleForm.imgList.push(list)
					}
				})
			},
			ok() {
				if (this.title == '修改反馈') {
					this.$axios.put('user/v1/updateWorkOrder', this.ruleForm).then((res) => {
						if (res.data.code == '1') {
							this.$message.success('修改成功')
							this.adddialogVisiblefalse = false
							this.getuserinfor()
						}
					})
				} else if (this.title == '新增反馈') {
					this.$axios.post('user/v1/insertWorkOrder', this.ruleForm).then((res) => {
						if (res.data.code == '1') {
							this.$message.success('新增成功')
							this.adddialogVisiblefalse = false
							this.getuserinfor()
						}
					})
				}

			},
			type(row) {
				if (row.type == 1) {
					return '系统建议'
				} else if (row.type == 2) {
					return '系统问题'
				}
			},
			status(row) {
				if (row.status == 1) {
					return '未解决'
				} else if (row.status == 2) {
					return '已解决'
				} else if (row.status == 3) {
					return '已删除'
				}
			},
			detail(val, row) {
				if (row == 1) {
					this.title = '反馈详情'
					this.adddialogVisiblefalse = true
					this.$axios.get(`user/v1/workOrderDetail?id=${val.id}`).then((res) => {
						if (res.data.code == '1') {
							this.ruleForm = res.data.data
							this.ruleForm.type = String(res.data.data.type)
						}
					})
				} else if (row == 2) {
					this.title = '修改反馈'
					this.adddialogVisiblefalse = true
					this.$axios.get(`user/v1/workOrderDetail?id=${val.id}`).then((res) => {
						if (res.data.code == '1') {
							this.ruleForm = res.data.data
							this.ruleForm.type = String(res.data.data.type)
						}
					})
				} else if (row == 3) {
					this.$axios.get(`user/v1/workOrderDetail?id=${val.id}`).then((res) => {
						if (res.data.code == '1') {
							this.ruleForm = res.data.data
							this.ruleForm.type = String(res.data.data.type)
						}
					})
					this.$confirm('此问题是否解决?', '提示', {
						confirmButtonText: '已解决',
						cancelButtonText: '未解决',
						type: 'warning'
					}).then(() => {
						this.ruleForm.status = 2
						this.$axios.put('user/v1/updateWorkOrder', this.ruleForm).then((res) => {
							if (res.data.code == '1') {
								this.$message.success('已确认')
								this.adddialogVisiblefalse = false
								this.getuserinfor()
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}

			},
			handleCurrentChange(row) {
				console.log(row);
				this.current = row
				this.getuserinfor()
			},
			handleSizeChange(row) {
				console.log(row);
			}
		}
	}
</script>

<style scoped="scoped">
	*,
	html,
	body,
	p {
		/* color: white; */
		/* font-family: Microsoft YaHei; */
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.el-menu-item {
		font-size: 15px !important;
		/* background-color: red; */

	}

	/deep/.el-submenu__title {
		font-size: 15px !important;
		height: 40px;
		line-height: 40px;
	}

	/deep/.el-submenu .el-menu-item {
		height: 40px;
		line-height: 40px;
	}

	/deep/.el-submenu__title i {
		color: #fff;
	}

	/deep/.el-form-item__error {
		color: #F56C6C;
		font-size: 12px;
		line-height: 1;
		padding-top: 4px;
		position: absolute;
		top: 60%;
		left: 0;
	}

	/deep/.el-dialog {
		position: relative;
		margin: 0 auto 50px;
		background: #FFF;
		border-radius: 2px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
		box-sizing: border-box;
		width: 600px;
	}

	.title-font {
		color: white;
		/* font-weight: 600; */
		font-size: calc(100vw * 20 / 1920);
		/* letter-spacing: 4px; */
		padding: 15px 0 15px 0;
		background-color: #1c95d4;
	}

	.el-menu {
		border-right: 0 !important;
	}

	.el-main {
		padding: 0 !important;
		background: #ffffff !important;
	}

	.el-header {
		padding: 0 !important;
	}

	/* .bg-home {
		background-color: #1c95d4;
	} */

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		min-width: 160px;
		min-height: 400px;
	}

	/deep/.el-submenu .el-menu-item {
		min-width: 0;
	}

	.el-menu-item.is-active {
		background-color: #fff !important;
	}

	.el-menu-item:hover {
		background-color: #bfe0f3;
	}

	/deep/.el-menu-item-group__title {
		padding: 0;
	}

	/deep/label {
		margin: 0;
	}
</style>